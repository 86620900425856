import { format } from "date-fns";
import formatRelative from "date-fns/formatRelative";
import enUS from "date-fns/locale/en-US";
import { i18nTranslate } from "@/plugins/i18n";
import { config } from "./config";

export const dateFormats = {
  default: "MM/DD/YYYY HH:mm:ss a",
  defaultDateWithNoTime: "MM/dd/yyyy",
  defaultDateWithNoTimePretty: "PP", // Apr 29, 1453
  dateTime: "MM/DD/YYYY, hh:mm:ss a",
  time: "hh:mm:ss a",
  shortTime: "hh:mm a",
  yearFormat: "YYYY",
};

/**
 * When using a string, we need to wrap words with ''
 */
const relativeDateFormat = `'('LLL dd Y')'`;
const formatRelativeLocale = {
  lastWeek: `'${i18nTranslate("last week")}' ${relativeDateFormat}`,
  yesterday: `'${i18nTranslate("yesterday")}'`,
  today: `'${i18nTranslate("today")}'`,
  tomorrow: `'${i18nTranslate("tomorrow")}'`,
  nextWeek: `'${i18nTranslate("next week")}' ${relativeDateFormat}`,
  other: `'${i18nTranslate("on")}' ${dateFormats.defaultDateWithNoTime}`, // LLL = Jan, Feb, etc... Y=2022 e.g. on May 2022
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

export const formatDate = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.defaultDateWithNoTime);

export const formatDatePretty = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.defaultDateWithNoTimePretty);

export const formatTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.time);

export const formatShortTime = (timestampStr: string | Date) =>
  format(new Date(timestampStr), dateFormats.shortTime);

export const formatDateTime = (timestampStr: string) =>
  format(new Date(timestampStr), dateFormats.dateTime);

export const dateFromNowPretty = (dateToFormat: Date | number) =>
  formatRelative(dateToFormat, new Date(), { locale });

export const getResendVerificationCodeDataMs = (
  dateLastSent: string,
  fallbackValue = 0
) => {
  if (!dateLastSent) {
    return fallbackValue;
  }
  return (
    new Date(dateLastSent).getTime() + config.resendVerificationCodeIntervalMs
  );
};
